<script setup>
  import VLazyImage from "v-lazy-image";
</script>
<template>
  <div v-if="loaded">

     <div class="container-fluid ">
    <img class="img-fluid" :src="imgUrl+'journal/'+currentData.jData.id" />

     </div>
    <div class="container-fluid pt-3">
    <div class="row flex-row flex-nowrap scrolling-wrapper-flexbox">
       <div class="px-1 pt-2" v-for="(item, index) in topItems" :key="index" @click="filterCategory(item)">
                <div class="card text-center">
                    <VLazyImage :key="item.id"  :class="item.id === selectedId ? 'card-img-top rounded active' : 'card-img-top rounded not-active'"   :src="imgUrl+'product/'+item.id" alt="" width="100%" />
                    <div class="card-block">
                    {{ item.category }}
                    </div>
                </div>
</div>
    </div>
</div>
  <section class="container row" v-if="loaded">
      <Product
        v-for="(product,index) in products"
        :key="product.id"  @click="showModal(imgUrl+'product/'+product.id)"
        :product="product" :journal="currentData.jData" :counter="(index+1)+' of '+products.length"
      />

     
    </section>
       <div ref="image_modal" id="image_modal" v-if ="modalShow" v-cloak  class="modal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
     
            <div class="modal-body">
                     <button type="button" class="close" aria-label="Close" @click="hideModal">
  <span aria-hidden="true">&times;</span>
</button>
              <img class="image" :src='selectedImgPath'/>
             
            </div>
          </div>
        </div>
      </div>
   
   <Footer />

     <transition name="fade">
    <div id="pagetop" class="fixed right-0 bottom-0" v-show="scY>300" @click="toTop">
     <svg width="48px" height="48" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.6907 4.8866C21.5876 3.54639 20.5567 2.41237 19.1134 2.30928C17.0515 2.10309 14.1649 2 12 2C9.83505 2 6.94845 2.10309 4.8866 2.30928C4.16495 2.30928 3.54639 2.61856 3.13402 3.13402C2.72165 3.64948 2.41237 4.16495 2.30928 4.8866C2.10309 6.94845 2 9.83505 2 12C2 14.1649 2.20619 17.0515 2.30928 19.1134C2.41237 20.4536 3.4433 21.5876 4.8866 21.6907C6.94845 21.8969 9.83505 22 12 22C14.1649 22 17.0515 21.7938 19.1134 21.6907C20.4536 21.5876 21.5876 20.5567 21.6907 19.1134C21.8969 17.0515 22 14.1649 22 12C22 9.83505 21.8969 6.94845 21.6907 4.8866ZM16.6392 13.5464C16.433 13.6495 16.3299 13.7526 16.1237 13.7526C15.9175 13.7526 15.7113 13.6495 15.6082 13.5464L12.2062 10.1443C12.1031 10.0412 11.8969 10.0412 11.7938 10.1443L8.39175 13.5464C8.08247 13.8557 7.56701 13.8557 7.25773 13.5464C6.94845 13.2371 6.94845 12.7216 7.25773 12.4124L10.6598 9.01031C11.3814 8.28866 12.5155 8.28866 13.2371 9.01031L16.6392 12.4124C16.9485 12.8247 16.9485 13.2371 16.6392 13.5464Z" fill="#030D45"/>
</svg>
    </div>
  </transition>
  </div>

  <div v-else>
    <br />
   <div class="spinner-border spinner" role="status">
  <span class="sr-only">Loading...</span>
</div>
  </div>
</template>

<script>
import MyPaaaDataService from "../services/MyPaaaDataService";
import Product from './utils/Product';
import Footer from './utils/Footer';

export default {
  
  name: "DetailsPage",
  inject: ['imgUrl'],
  components: {
    Product,
    Footer
  },
  data() {
    return {
      currentData: null,
      message: '',
      loaded : false,
      subData:null,
      topItems:null,
      products:null,
      scTimer: 0,
      scY: 0,
      modalShow: false,
      selectedImgPath: '',
      selectedId:''
    };
  },
  methods: {
    filterTop(datas){
     const key = 'category';
      const arrayUniqueByKey = [...new Map(datas.map(item =>
  [item[key], item])).values()];
return arrayUniqueByKey;
    },
     filterCategory(item){
       let category= item.category;
       this.selectedId = item.id
     //const key = 'category';
      const arrayUniqueByKey = this.subData.filter(item => item.category === category); 
       this.products =  arrayUniqueByKey;
    },
    get(id) {
      MyPaaaDataService.get(id)
        .then(response => {
          this.currentData = response.data;
          this.subData = this.currentData.subData;
          this.topItems = this.filterTop(this.subData );
          this.products =  this.subData ;
          this.emitter.emit('updateTitle', this.currentData.jData.title);
          this.loaded = true;
        })
        .catch(e => {
          console.log(e);
           this.loaded = true;
        });
    },
     showModal(imgPath) {
      this.selectedImgPath = imgPath
      this.modalShow = true
    },
    hideModal(){
 this.modalShow = false
    },
  handleScroll: function () {
        if (this.scTimer) return;
        this.scTimer = setTimeout(() => {
          this.scY = window.scrollY;
          console.log(this.scY);
          clearTimeout(this.scTimer);
          this.scTimer = 0;
        }, 100);
      },
      toTop: function () {
       // alert('aaa')
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      },
  
  },
  mounted() {
    this.message = '';
     this.loaded = false ;
    this.get(this.$route.params.id);
     window.addEventListener('scroll', this.handleScroll);
  }
};

</script>

<style>
.modal{
  display: inline-block !important;
}
.modal-dialog {
  max-width: 97% !important;
}
.modal > .modal-dialog > .modal-content > .modal-header {
  background-color: #0e0505b7 !important;
  color: white;
}
.edit-form {
  max-width: 300px;
  margin: auto;
}
.spinner {
  border: 1px solid;
  position: fixed;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
  margin: auto;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 50 50'%3E%3Cpath d='M28.43 6.378C18.27 4.586 8.58 11.37 6.788 21.533c-1.791 10.161 4.994 19.851 15.155 21.643l.707-4.006C14.7 37.768 9.392 30.189 10.794 22.24c1.401-7.95 8.981-13.258 16.93-11.856l.707-4.006z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E") center / contain no-repeat;
}
.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
 
}
 .scrolling-wrapper .card {
    display: inline-block;
  }
.scrolling-wrapper-flexbox {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  
  
}
.scrolling-wrapper-flexbox .card {
    flex: 0 0 auto;
    margin-right: 3px;
  }
.card {
  border: 2px solid rgb(244, 244, 248);
  width: 120px;
  height: 75px;
  background: black;
}

.scrolling-wrapper, .scrolling-wrapper-flexbox {
  height: 150px;
  margin-bottom: 20px;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  
}
html {
    overflow: scroll;
    overflow-x: hidden;
}
::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: #faf8f8;
}
::-webkit-scrollbar {
    display: none;
  }
   .products {
    display: flex;
    flex-direction: column ;
    max-width: 100%;
    padding: 25px;
    margin: 0 auto;
  }
  #btn-back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: none;
}
#pagetop{
  position:fixed;
  bottom: 5px;
  right: 5px;
}
.active {
  border: 5px solid #f7d306f5;
}
.not-active {
  border: 1px solid #fafbfcf5;
}

</style>
